<template>
  <div class="course-container">
    <div class="course-container-title">我的消息</div>
    <div class="course-group">
      <div class="course-group-tab">
        <a-tabs type="card-gutter" :default-active-key="active" v-model="active" @change="change">
          <a-tab-pane v-for="(item, index) in tab" :key="index" :title="item"></a-tab-pane>
        </a-tabs>
      </div>
    </div>
    <a-spin :loading="loading" style="width: 100%">
      <div class="sys-list" v-if="active != 1">
        <div v-if="sysList && sysList.length > 0">
          <div class="sys-item" v-for="(item, index) in sysList" :key="index">
            <div style="flex: 1; cursor: pointer;" @click="handleStatus(item)">
              <div class="sys-item-title" :style="{ 'color': item.state == 1 ? '#323233': '#7b7b7b'}">{{item.title + index}}</div>
              <div class="sys-item-date">{{item.publishTime}}</div>
              <div class="sys-item-content" :style="{ 'color': item.state == 1 ? '#323233': '#7b7b7b'}">{{item.content}}</div>
            </div>
            <div class="sys-item-close">
              <div class="sys-item-close-btn"><icon-close @click="handleDel(item, index)" size="30" style="cursor: pointer" /></div>
            </div>
          </div>
        </div>
        <a-empty style="margin-top: 20px;" v-else>暂无记录</a-empty>
      </div>
      <div class="interaction-list" v-else>
        <a-empty style="margin-top: 20px;" >暂无记录</a-empty>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { getNoticeList, getNoticeRand, getNoticeDel } from '@/api/message.js'
import { Message} from '@arco-design/web-vue'
import { Modal } from '@arco-design/web-vue'

export default {
  setup () {
    const store = useStore()
    const state = reactive({
      loading: false,
      tab: ['系统通知', '互动交流'],
      active: 0,
      sysList: [],
      interaction: []
    })
    const change = val => {
      state.active = val
    }
    // 初始化 获取列表
    const getLists = () => {
      state.loading = true
      getNoticeList().then(res => {
        state.loading = false
        if (res.code === 20000) {
          state.sysList = res.result.records
        }
      })
    }
    getLists()
    // 改变状态  已读
    const handleStatus = item => {
      if (item.state == 1) {
        getNoticeRand({ id: item.id }).then(res => {
          if (res.code === 20000) {
            Message.success('已标为已读')
            item.state = 2
            store.dispatch('updateCount')
          }
        })
      }
    }
    // 删除
    const handleDel = (item, index) => {
      // Modal.error({
      //   titleAlign: 'start',
      //   title: '删除',
      //   content: '是否确认删除',
      //   hideCancel: false,
      //   cancelText: '取消',
      //   okText: '确定',
      //   onOk: () => {
      //     console.log(111)
      //   }
      // })
      getNoticeDel({id: item.id}).then(res => {
        if (res.code === 20000) {
          Message.success('删除成功')
          state.sysList.splice(index, 1)
          if (item.state == 1) {
            store.dispatch('updateCount')
          }
        }
      })
    }
    return {
      ...toRefs(state),
      change,
      handleStatus,
      handleDel
    }
  }
}
</script>

<style lang="less" >
.course-container {
  .arco-tabs-type-card-gutter > .arco-tabs-content {
    display: none !important;
  }
  .arco-tabs-nav-type-card-gutter .arco-tabs-tab:first-child {
    margin-left: 0px;
  }
  .arco-tabs-nav-type-card-gutter .arco-tabs-tab {
    margin-left: 20px;
    border-radius: 4px;
    // padding: 4px 20px;
  }
  .arco-tabs-nav-type-card-gutter .arco-tabs-tab.arco-tabs-tab-active {
    background: #4F17A8;
    color: #FFFFFF;
  }
  .arco-tabs-nav::before {
    display: none;
  }
}
</style>

<style lang="less" scoped>
.course-container {
  width: 100%;
  // min-height: calc(100vh - 400px);
  padding: 20px 30px 50px;
  box-sizing: border-box;
  background-color: #fff;
  .course-container-title {
    color: #323233;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
  }
}

.sys-list {
  margin-top: 20px;
  border-top: 1px solid #E9E9E9;
  .sys-item:hover {
    .sys-item-close-btn {
      display: block;
    }
  }
  .sys-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E9E9E9;
    padding: 10px 0 20px;
    color: #7B7B7B;
    .sys-item-close {
      width: 60px;
      text-align: right;
    }
    .sys-item-close-btn {
      display: none;
    }
    .sys-item-title {
      font-size: 16px;
      line-height: 22px;
      font-weight: 700;
    }
    .sys-item-date {
      margin: 6px 0 10px;
      font-size: 12px;
      line-height: 17px;
    }
    .sys-item-content {
      font-size: 14px;
      line-height: 20px;
    }
  }

}

</style>
